<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="body">
      <a-table :columns="columns" :data-source="tableData" :rowKey="(record,index)=> index" :pagination="false" bordered>
        <template
            v-for="col in ['patroltype', 'managetype', 'linenumber','zero','one','two','three','four','five','six','seven','eight', 'nine', 'ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen','seventeen', 'eighteen','nineteen','twenty','twentyone','twentytwo','twentythree']"
            :slot="col"
            slot-scope="text, record, index"
        >
          <div :key="col">
            <span v-if="col == 'patroltype'" style="margin: -5px 0" :value="text">{{patrolTypes[record.patroltype]?patrolTypes[record.patroltype].value:''}}</span>
            <span v-if="col == 'managetype'" style="margin: -5px 0" :value="text">{{record.managetype}}</span>
            <a v-if="col == 'linenumber'" style="width: 80px" @click="showSchemeModal(record)">{{record.linenumber}}</a>
            <span v-if="col == 'zero'" style="width: 65px"></span>
            <span v-if="col == 'one'" style="width: 65px"></span>
            <span v-if="col == 'two'" style="width: 65px"></span>
            <span v-if="col == 'three'" style="width: 65px"></span>
            <span v-if="col == 'four'" style="width: 65px"></span>
            <span v-if="col == 'five'" style="width: 65px"></span>
            <span v-if="col == 'six'" style="width: 65px"></span>
            <span v-if="col == 'seven'" style="width: 65px"></span>
            <span v-if="col == 'eight'" style="width: 65px"></span>
            <span v-if="col == 'nine'" style="width: 65px"></span>
            <span v-if="col == 'ten'" style="width: 65px"></span>
            <span v-if="col == 'eleven'" style="width: 65px"></span>
            <span v-if="col == 'twelve'" style="width: 65px"></span>
            <span v-if="col == 'thirteen'" style="width: 65px"></span>
            <span v-if="col == 'fourteen'" style="width: 65px"></span>
            <span v-if="col == 'fifteen'" style="width: 65px"></span>
            <span v-if="col == 'sixteen'" style="width: 65px"></span>
            <span v-if="col == 'seventeen'" style="width: 65px"></span>
            <span v-if="col == 'eighteen'" style="width: 65px"></span>
            <span v-if="col == 'nineteen'" style="width: 65px"></span>
            <span v-if="col == 'twenty'" style="width: 65px"></span>
            <span v-if="col == 'twentyone'" style="width: 65px"></span>
            <span v-if="col == 'twentytwo'" style="width: 65px"></span>
            <span v-if="col == 'twentythree'" style="width: 65px"></span>
          </div>
        </template>
      </a-table>
    </div>
    <patrol-scheme-modal :visible.sync="schemeModalVisible" :show-type="schemeModalShowType" :detailData="schemeModalDetailData"></patrol-scheme-modal>
  </page-container>
</template>
<script>
import {mapState} from "vuex";
import {getMonitorPointNameList} from "A/monitoring";
import { getPatrolSchemeTemplate} from "A/patrol";
import moment from "moment";
import patrolSchemeModal from "V/material/patrolScheme/patrolSchemeModal";
import {patrolTypes} from "@/json/wlwhistory";
export default {
  name: "patrolTemplate",
  components:{
    patrolSchemeModal
  },
  data() {
    return {
      moment,
      patrolTypes,
      time:['zero','one','two','three','four','five','six','seven','eight', 'nine', 'ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen','seventeen', 'eighteen','nineteen','twenty','twentyone','twentytwo','twentythree'],
      columns:[
        {
          title: '序号',
          dataIndex: 'keynum',
          // width: 40,
        },
        {
          title: '巡查类型',
          colSpan: 1,
          dataIndex: 'patroltype',
          width: 80,
          scopedSlots: { customRender: 'patroltype' },
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          width: 120
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          width: 120
        },
      ],
      tableData:[],
      option:['正常','异常'],
      queryParams:{
        monitorpointnum:'',
      },
      monitorpointNameList:[],
      schemeModalVisible: false,
      schemeModalShowType: 'edit',
      schemeModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "巡查模板"
    },
    breadcrumb() {
      const pages = [{name:this.$route.params.modalTitle,path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  created() {
    this.getPatrolSchemeTemplate(this.$route.params.monitorpointnum,this.$route.params.patrolshift,this.$route.params.patroltemplateid)
  },
  methods: {
    showSchemeModal(record){
      this.schemeModalVisible=true
      this.schemeModalDetailData=record.patrolschemeid
    },
    getPatrolSchemeTemplate(monitorpointnum,patrolshift,patroltemplateid){
      this.columns=[
        {
          title: '序号',
          dataIndex: 'keynum',
          width: 50,
        },
        {
          title: '巡查类型',
          colSpan: 1,
          dataIndex: 'patroltype',
          width: 80,
          scopedSlots: { customRender: 'patroltype' },
        },
        {
          title: '管理类型',
          dataIndex: 'managetype',
          width: 160
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          scopedSlots: { customRender: 'linenumber' },
          align: 'center',
          width: 120
        },
      ]
      getPatrolSchemeTemplate({monitorpointnum:monitorpointnum,patrolshift: patrolshift,patroltemplateid:patroltemplateid?patroltemplateid:null}).then(res=>{
        if(res&&res.returncode=='0') {
          let suffix = res.start.substring(3,5)
          let start = parseInt(res.start.substring(0,2))
          let end = suffix=="00"?parseInt(res.end.substring(0,2))+1:parseInt(res.end.substring(0,2))
          let intervals = res.intervals==0?1:res.intervals==1?2:4
          let lastHour=0
          if (start > end) {
            for (let i = start; i < 24; i+=intervals) {
              this.columns.push({
                title: i + ':'+suffix,
                dataIndex: this.time[i],
                scopedSlots: {customRender: this.time[i]},
              })
              if(i<24){lastHour=i}
            }
            for (let i = 0-(24-lastHour); i < end; i+=intervals) {
              if(i<0){continue}
              this.columns.push({
                title: i + ':'+suffix,
                dataIndex: this.time[i],
                scopedSlots: {customRender: this.time[i]},
              })
            }
          } else {
            for (let i = start; i < end; i+=intervals) {
              this.columns.push({
                title: i + ':'+suffix,
                dataIndex: this.time[i],
                scopedSlots: {customRender: this.time[i]},
              })
            }
          }
          this.tableData = res.item
        }
      })
    },
  },
}
</script>
<style scoped>
.body{
  background-color: white;
  margin: 10px;
  width: 100%
}
</style>